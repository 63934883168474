@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Montserrat:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Montserrat:wght@300;400;500&family=Roboto+Mono:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Montserrat:wght@300;400;500&family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&family=Roboto+Mono:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Montserrat:wght@300;400;500&family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&family=Roboto+Mono:wght@200;300;400&family=Work+Sans:ital,wght@0,300;0,400;0,600;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Montserrat:wght@300;400;500&family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&family=Open+Sans:wght@300;400;500;600&family=Roboto+Mono:wght@200;300;400&family=Work+Sans:ital,wght@0,300;0,400;0,600;1,600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

#inputField {
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  position: relative;
}

#inputField::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #2196f3; /* Change to your desired color */
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
}

#inputField:focus::before {
  width: 100%;
}

/* In your CSS file or stylesheets */
.ant-menu {
  border: none !important;
}

/* Feedback Componenet */
.feedback-custom-modal {
  /* Add your custom styles for the modal container */
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px;
}

.feedback-modal-text {
  /* Add your custom styles for the text paragraphs */
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Center-align the rating component */
.center-align-rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

/* Center-align the rating component at the bottom */
.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto; /* Push to the bottom */
}

/* Center-align the buttons */
.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Make buttons full width */
  margin-top: 16px; /* Adjust spacing as needed */
}

/* .ant-modal-content{
  padding:"0px 0px"
} */

.PhoneInputInput {
  padding: 5px;
}

.PhoneInputInput:focus {
  outline: none;
}
/* menu.css */
.menu-link {
  /* Your regular link styles go here */
  color: #000; /* Set your desired link color */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for color change */

  /* Additional styles for the hover effect */
  position: relative;
}

.menu-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the underline thickness as needed */
  background-color: #f97316; /* Set your desired hover color */
  transform: scaleX(
    0
  ); /* Initial width of the underline, it will grow on hover */
  transform-origin: right;
  transition: transform 0.3s ease; /* Smooth transition for underline animation */
}

.menu-link:hover {
  color: #f97316; /* Change the link color on hover */
}

.menu-link:hover::after {
  transform: scaleX(1); /* Expand the underline on hover */
  transform-origin: left;
}

@layer components {
  .navStyle {
    @apply inline-block bg-orange-500 rounded text-xs font-medium leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-[#FF5C00] tracking-wide;
  }
}
.menu-link.active {
  color: #f97316;
}

.menu-link .active::after {
  transform: scaleX(1);
}

.ant-result .ant-result-icon {
  margin-bottom: 0px;
}
.ant-result .ant-result-title {
  margin: 0px 0px;
  margin-block: 0px;
}
.slick-slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: #f97316 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: "100%";
}
.slick-slider .slick-track {
  display: flex !important;
  align-items: center;
}
.slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

